<template>
  <section id="calendar-wrapper">
    <FullCalendar
      v-loading="loading"
      v-for="(hours, index) in calendarDisplayHours"
      :key="hours.start"
      ref="fullCalendar"
      schedulerLicenseKey="0150998614-fcs-1526870019"
      locale="ko"
      :height="calendarDisplayHours.length <= 1 ? 'parent' : 'auto'"
      :plugins="calendarPlugins"
      :defaultView="defaultView"
      :defaultDate="datepickerValues[calendarViewType]"
      :firstDay="1"
      :hiddenDays="defaultView === 'timeGridWeek' ? hiddenDays : []"
      :allDaySlot="false"
      :header="false"
      :slotLabelFormat="slotLabelFormat"
      :slotEventOverlap="false"
      :minTime="hours.start"
      :maxTime="hours.end"
      :navLinks="true"
      :navLinkDayClick="navLinkDayClick"
      :columnHeader="index === 0"
      :columnHeaderHtml="defaultView === 'dayGridMonth' ? null : columnHeaderHtml"
      :resources="resources"
      :resourceOrder="['order', 'id']"
      :events="filteredEvents"
      :eventOrder="['start', 'lectureTitle', 'id']"
      @eventRender="eventRender"
      :selectable="true"
      :unselectAuto="true"
      @select="select"
      :editable="true"
      @eventDrop="eventDrop"
      @eventResize="eventResize"
      :droppable="true"
      @eventLeave="eventLeave"
      @eventReceive="eventReceive"
      @drop="eventDropFromOutside"
      @viewSkeletonRender="viewSkeletonRender"
      :eventLimit="5"
      :eventLimitText="formatEventLimitText"
      :fixedWeekCount="false"
      @datesRender="datesRender"
    />

    <!-- v-if="canCreatePrivateLecture() || canCreateGroupLecture()" -->

    <floating-action-button
      @click="handleClickCreateButton"
      v-if="this.canCreateCounsel || this.canCreatePrivateLecture() || this.canCreateGroupLecture()"
    />

    <create-lesson-modal
      :payload="paramsNewClass"
      :show="showCreateLessonModal"
      :onClose="handleCloseCreateLessonModal"
      :checkDisabled="createGroupDisabled"
    />
  </section>
</template>

<script>
import Vue from 'vue';
/** Full Calendar */
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import '@fullcalendar/core/main.min.css';
import '@fullcalendar/daygrid/main.min.css';
import '@fullcalendar/timegrid/main.min.css';
/** Components */
import EventItem from '@/components/Schedule/EventItem';
import CreateLessonModal from '@/components/Modals/CreateLesson';

export default {
  components: { FullCalendar, CreateLessonModal },

  data() {
    return {
      /** FullCalendar Options */
      calendarPlugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, resourceTimeGridPlugin],
      slotLabelFormat: {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        omitZeroMinute: false,
      },

      showCreateLessonModal: false,
      checkCurrentTimeInterval: null,

      /** Events move between Calendars */
      oldEvent: null,
      newResource: null,
      paramsNewClass: Object,
      pressedESC: false,
      click_couseling: '',
      createGroupDisabled: false,
    };
  },

  computed: {
    holidays() {
      return this.$store.getters['studio/holidays'];
    },
    calendarViewType() {
      return this.$store.getters['schedule/calendarViewType'];
    },
    calendarDisplayHours() {
      return this.$store.getters['schedule/calendarDisplayHours'];
    },
    calendarDisplayWeekdays() {
      return this.$store.getters['schedule/calendarDisplayWeekdays'];
    },
    calendarDisplayLectureColor() {
      return this.$store.getters['schedule/calendarDisplayLectureColor'];
    },
    datepickerValues() {
      return this.$store.getters['schedule/datepickerValues'];
    },
    filters() {
      return this.$store.getters['schedule/filters'];
    },
    loading() {
      return this.$store.getters['schedule/loading'];
    },
    events() {
      return this.$store.getters['schedule/events'];
    },
    staffOptions() {
      return this.$store.getters['schedule/staffOptions'];
    },
    rooms() {
      return this.$store.getters['schedule/rooms'];
    },

    viewType() {
      return this.calendarViewType.split('|')[0];
    },

    resourceType() {
      return this.calendarViewType.split('|')[1];
    },

    defaultView() {
      let defaultView = 'timeGridWeek';
      if (this.viewType === 'date') {
        if (window.innerWidth < 568) {
          defaultView = 'timeGridDay';
        } else {
          defaultView = 'resourceTimeGridDay';
        }
      } else if (this.viewType === 'month') {
        defaultView = 'dayGridMonth';
      }
      return defaultView;
    },

    hiddenDays() {
      return ['월', '화', '수', '목', '금', '토', '일']
        .filter(weekday => !this.calendarDisplayWeekdays.includes(weekday))
        .map(weekday => Number(this.$utils.translate.weekdayText(weekday)));
    },

    filteredEvents() {
      if (this.loading) return [];

      const { eventType, staff } = this.filters;
      // instructor 또는 room (월간/주간/일간(강사별), 일간-룸별)
      const resource = this.calendarViewType.split('|')[1] || 'instructor';

      const eventArrayWithResourceId = this.events.map(event => ({
        // append resource id
        ...event,
        resourceId: event[`${resource}Id`] ? event[`${resource}Id`] : null,
      }));
      let filteredEvents = [];

      // 이벤트 타입 필터 적용
      const applyEventTypeFilter = event => {
        if (eventType === 'private') return event.courseType === 'P';
        if (eventType === 'group') return event.courseType === 'G';
        if (eventType === 'counseling') return event.type === 'counsel';
        return true;
      };

      // 강사별보기 필터 적용
      const applyStaffFilter = instructorId => {
        if (staff.showAll) return true;

        if (Array.isArray(instructorId)) {
          return instructorId.some(item => staff.ids.includes(item));
        }
        return staff.ids.includes(instructorId);
      };

      // 권한 적용
      const applyPermissionFilter = event => {
        const { type, courseType, instructorId, isPrivate } = event;

        if (type === 'lecture') {
          return this.canViewLecture(courseType, instructorId);
        }
        if (type === 'counsel') {
          return instructorId === this.currentUser.id || this.canViewCounsel;
        }
        if (type === 'etcSchedule') {
          const hasCurrentUser = Array.isArray(instructorId) && instructorId.includes(this.currentUser.id);
          return !isPrivate || hasCurrentUser;
        }
        return true;
      };

      // 일간(강사별, 룸별) 탭 필터
      const applyDailyViewFilter = event => {
        const isEtcSchedule = Array.isArray(event.instructorId);
        const isPrivateEvent = event.isPrivate;

        if (isEtcSchedule) {
          // 기타일정은 전체공개 or 현재 로그인 강사가 비공개 일정에 포함된 경우 일정 노출
          return !isPrivateEvent || event.instructorId.includes(this.currentUser.id);
        }

        return true;
      };

      /** 일간 이벤트 (기타일정은 강사 여러명 선택 가능해서 강사마다 동일한 이벤트 아이템 생성) */
      if (this.calendarViewType.includes('date')) {
        eventArrayWithResourceId.forEach(event => {
          const resourceIds = Array.isArray(event.resourceId) ? event.resourceId : [event.resourceId];

          // resourceIds = 강사 아이디(강사별) or 룸 아이디(룸별)
          resourceIds.forEach(id => {
            const dayEvent = { ...event, resourceId: id };
            let showEvent = true;

            // 각종 필터 적용
            showEvent = showEvent && applyDailyViewFilter(event);
            showEvent = showEvent && applyEventTypeFilter(event);
            showEvent = showEvent && applyStaffFilter(event.instructorId);
            showEvent = showEvent && applyPermissionFilter(event);

            if (showEvent) {
              filteredEvents.push(dayEvent);
            }
          });
        });
      } else {
        /** 일간 외 이벤트들 */
        filteredEvents = eventArrayWithResourceId.filter(event => {
          let showEvent = true;

          showEvent = showEvent && applyEventTypeFilter(event);
          showEvent = showEvent && applyStaffFilter(event.instructorId);
          showEvent = showEvent && applyPermissionFilter(event);

          return showEvent;
        });
      }

      return filteredEvents;
    },

    eventsCountByWeekday() {
      let count = {};
      this.filteredEvents.forEach(event => {
        const { start, courseType } = event;
        const date = start.slice(0, 10);
        if (count[date]) {
          if (count[date][courseType]) {
            count[date][courseType]++;
          } else {
            count[date][courseType] = 1;
          }
        } else {
          count[date] = { G: 0, P: 0 };
          count[date][courseType] = 1;
        }
      });
      return count;
    },

    /** 강사/룸 목록 (일별 보기용) */
    resources() {
      if (!this.staffOptions && !this.rooms) return [];

      if (this.resourceType === 'room') {
        return this.rooms.map(room => ({ id: room.id, title: room.name }));
      }

      return this.staffOptions
        .filter(({ id }) => this.filters.staff.ids.includes(id))
        .map(staff => ({
          id: staff.id,
          title: _.get(staff, 'profile.name'),
          eventColor: _.get(staff, 'profile.representative_color') || '000',
          order: staff.order,
        }));
    },
  },

  watch: {
    calendarViewType() {
      this.handleChangeCalendarControls();
    },
    'datepickerValues.week'() {
      this.handleChangeCalendarControls();
    },
    'datepickerValues.date'() {
      this.handleChangeCalendarControls();
    },
    'datepickerValues.month'() {
      this.handleChangeCalendarControls();
    },
    filters() {
      this.handleChangeCalendarFilters();
    },
  },

  async created() {
    this.$store.dispatch('schedule/getEvents');
    this.$store.dispatch('schedule/getRooms');

    window.addEventListener('keydown', this.pressESC);
    this.$nextTick(() => {
      if (window.innerWidth < 568) {
        this.$store.commit('schedule/SET_CALENDAR_VIEW_TYPE', 'date');
      }
      window.addEventListener('resize', _.throttle(this.handleResizeWindow, 300));
      this.checkCurrentTimeInterval = setInterval(this.checkCurrentTime, 1000 * 60);

      this.$refs.fullCalendar.forEach(ref => {
        ref.getApi().changeView(this.defaultView, this.datepickerValues[this.viewType]);
      });
    });
  },

  destroyed() {
    window.removeEventListener('resize', this.handleResizeWindow);
    window.removeEventListener('keydown', this.pressESC);
    clearInterval(this.checkCurrentTimeInterval);
    this.$utils.removePopovers();
  },

  methods: {
    pressESC(e) {
      if (e.key == 'Escape') {
        this.pressedESC = true;
      }
    },
    handleResizeWindow() {
      if (window.innerWidth < 568) {
        this.$store.commit('schedule/SET_CALENDAR_VIEW_TYPE', 'date');
      } else {
        const viewType = this.$utils.localStorage.get('calendarViewType') || 'week';
        this.$store.commit('schedule/SET_CALENDAR_VIEW_TYPE', viewType);
      }
    },

    async handleChangeCalendarControls() {
      await this.$store.dispatch('schedule/getEvents');
      const date = this.datepickerValues[this.viewType];
      this.$refs.fullCalendar.forEach(ref => {
        ref.getApi().changeView(this.defaultView, date);
      });
    },

    handleChangeCalendarFilters() {
      this.$refs.fullCalendar[0].getApi().refetchEvents();
      this.$refs.fullCalendar[0].getApi().refetchResources();
    },

    handleClickCreateButton() {
      this.showCreateLessonModal = true;
    },

    handleCloseCreateLessonModal() {
      this.showCreateLessonModal = false;
    },

    /** 날짜 클릭시 이벤트 */
    navLinkDayClick(date) {
      const dateTo = this.moment(date).format('YYYY-MM-DD');
      this.$store.commit('schedule/SET_CALENDAR_VIEW_TYPE', 'date|instructor');
      this.$store.commit('schedule/SET_DATEPICKER_VALUES', dateTo);
    },

    /** 달력 상단 날짜 렌더링 */
    columnHeaderHtml(mom) {
      const date = this.moment(mom);
      const dateLong = date.format('YYYY-MM-DD');
      const count = this.eventsCountByWeekday[dateLong] || { P: 0, G: 0 };
      const isToday = dateLong === this.moment().format('YYYY-MM-DD') ? 'is_today' : '';
      const isHoliday = this.holidays.includes(dateLong);

      return `
          <div class="calendar-column-header">
            <div class="calendar-column-header__tags">
              <span class="calendar-column-header__tags__today ${isToday}"></span>
              <span class="calendar-column-header__tags__holiday">${isHoliday ? '휴일' : ''}</span>
            </div>
            <div class="calendar-column-header__date">
              <h5 class="${isToday}">${date.format('D')}</h5>
              <span>${date.format('ddd')}</span>
            </div>
            <div class="calendar-column-header__lecture-count">
              <span>프라이빗 <b>${count.P}</b></span>
              <span>그룹 <b>${count.G}</b></span>
            </div>
          </div>
        `;
    },

    /** 이벤트 아이템 렌더링 */
    eventRender({ el, event }) {
      const EventItemClass = Vue.extend(EventItem);
      const instance = new EventItemClass({
        propsData: {
          event,
          viewType: this.calendarViewType,
          isShowBackGroundColor: this.calendarDisplayLectureColor,
        },
      });
      instance.$mount();

      el.removeChild(el.querySelector('.fc-content'));
      el.appendChild(instance.$el);
      el.addEventListener('click', () => {
        this.eventClick(event);
      });
      el.addEventListener('touchend', () => {
        this.eventClick(event);
      });
    },

    /** 이벤트 클릭 */
    eventClick(event) {
      // 기타일정 이벤트 클릭 방지
      if (event.extendedProps.type === 'etcSchedule') return;
      if (event.extendedProps.type === 'lecture') {
        this.$router.push(`/lecture/detail?id=${event.id}`);
      } else {
        const { moment } = this;
        const { name, contents, phone, instructorId } = event.extendedProps;
        const params = {
          id: event.id,
          staff_id: instructorId,
          name: name,
          contents: contents,
          phone: phone,
          start_on: moment(event.start).format('YYYY-MM-DD HH:mm:ss'),
          end_on: moment(event.end).format('YYYY-MM-DD HH:mm:ss'),
        };

        this.$router.push({ name: 'counsels', params });
      }
    },

    /** 달력 빈공간 선택시 */
    async select({ start, end, resource }) {
      const startOn = this.moment(start);
      const endOn = this.moment(end);
      const daysDiff = endOn.diff(startOn, 'days');

      const monthAgo = this.moment()
        .subtract(1, 'M')
        .format('YYYY-MM-DD');

      const checkMonthAgo = monthAgo <= startOn.format('YYYY-MM-DD');

      if (!checkMonthAgo) this.createGroupDisabled = true;
      else this.createGroupDisabled = false;

      const endTime = endOn.format('HH:mm');
      const startDate = startOn.format('YYYYMMDD');
      const endDate = endTime === '00:00' ? endOn.subtract(1, 'days').format('YYYYMMDD') : endOn.format('YYYYMMDD');

      if (startDate !== endDate) return;

      const start_on =
        daysDiff === 0
          ? startOn.format('YYYY-MM-DD HH:mm')
          : startOn
              .hour(this.moment().hour())
              .startOf('hour')
              .format('YYYY-MM-DD HH:mm:ss');
      const end_on =
        daysDiff === 0
          ? endOn.format('YYYY-MM-DD HH:mm')
          : endOn
              .hour(this.moment().hour())
              .startOf('hour')
              .add(30, 'minutes')
              .format('YYYY-MM-DD HH:mm:ss');
      const { eventType } = this.filters;
      let eventTypeText = eventType === 'counseling' ? '상담' : '프라이빗 수업';
      let title = `${eventTypeText} 등록`;

      /** 확인 메시지 */
      let message = `일시:&ensp;<b>${this.$filters.datetime(start_on)} ~ ${this.$filters.datetime(end_on)}</b><br>`;
      // if (resource && this.resourceType === 'instructor') {
      //   message += `강사:&ensp;<b>${resource.title}</b><br>`;
      // } else if (this.resourceType === 'room' && eventType !== 'counseling') {
      //   message += `룸:&ensp;<b>${resource.title}</b><br>`;
      // }
      message += `${eventTypeText}을 등록 하시겠습니까?`;

      /** 권한 체크 */
      if (!this.canCreateCounsel && !this.canCreatePrivateLecture() && !this.canCreateGroupLecture())
        return this.$utils.notify.error(this, '권한이 없습니다.');

      // this.paramsNewClass= { start_on, end_on };
      this.paramsNewClass = { start_on, end_on };
      if (resource && this.resourceType === 'instructor') {
        this.paramsNewClass = { ...this.paramsNewClass, instructor_id: resource.id };
      } else if (this.resourceType === 'room' && eventType !== 'counseling') {
        this.paramsNewClass = { ...this.paramsNewClass, room_id: resource.id };
      }

      /* 수업 등록일 경우 팝업 */
      if (eventType !== 'counseling') {
        this.handleClickCreateButton();
      } else if (eventType === 'counseling') {
        /* 상담 등록일 경우 팝업 */
        const proceed = await this.$confirm(message, title, {
          confirmButtonText: '등록',
          cancelButtonText: '취소',
          dangerouslyUseHTMLString: true,
        })
          .then(() => true)
          .catch(() => false);

        if (proceed) {
          const instructor_id = this.resourceType === 'room' ? null : resource?.id;
          this.$router.push({
            name: 'counsels',
            params: {
              visitCounseling: true,
              instructor_id,
            },
            query: {
              start_on,
              end_on,
            },
          });
        }
      }
    },

    /** 이벤트 드래그/리사이즈 */
    eventDrop({ event, oldEvent, revert, oldResource, newResource }) {
      this.updateLecture('drop', event, oldEvent, revert, oldResource, newResource);
    },
    eventResize({ event, prevEvent, revert }) {
      this.updateLecture('resize', event, prevEvent, revert);
    },

    /**
     * 이벤트 달력간 이동
     * eventLeave -> drop -> eventReceive 순으로 실행됨
     */
    eventLeave({ event }) {
      this.oldEvent = event;
    },
    eventDropFromOutside({ resource }) {
      this.newResource = resource;
    },
    eventReceive({ event }) {
      const oldResource = !this.newResource
        ? null
        : {
            id: this.oldEvent.extendedProps[`${this.resourceType}Id`],
            title:
              this.resourceType === 'instructor'
                ? this.oldEvent.extendedProps[this.resourceType]
                : this.oldEvent.extendedProps[`${this.resourceType}Name`],
          };

      const revert = () => {
        this.$store.dispatch('schedule/getEvents');
        event.remove();
        this.oldEvent = null;
        this.newResource = null;
      };

      this.updateLecture('drop', event, this.oldEvent, revert, oldResource, this.newResource, true);
    },

    formatEventLimitText(count) {
      return `${count}개 더보기`;
    },

    /** 휴일 표시/해제 */
    datesRender({ view }) {
      if (this.calendarViewType === 'week') {
        const dayHeaderElements = view.el.querySelectorAll('.fc-day-header[data-date]');
        dayHeaderElements.forEach(element => {
          const isHoliday = this.holidays.includes(element.dataset.date);
          const holidayTagElement = element.querySelector('.calendar-column-header__tags__holiday');
          if (isHoliday) holidayTagElement.innerHTML = '휴일';
          else holidayTagElement.innerHTML = '';
        });
      } else if (this.calendarViewType === 'month') {
        const dayTopElements = view.el.querySelectorAll('.fc-day-top[data-date]');
        dayTopElements.forEach(element => {
          element.style.padding = '4px';
          const dayNumberElement = element.querySelector('.fc-day-number');

          element.removeChild(element.firstChild);
          const wrapperEl = document.createElement('div');
          wrapperEl.style.width = '100%';
          wrapperEl.style.display = 'flex';
          wrapperEl.style.alignItems = 'center';
          wrapperEl.style.justifyContent = 'space-between';

          const isHoliday = this.holidays.includes(element.dataset.date);
          const holidayTagElement = document.createElement('span');
          holidayTagElement.style.color = '#f56c6c';
          holidayTagElement.style.fontSize = '13px';
          holidayTagElement.style.fontWeight = '700';
          if (isHoliday) holidayTagElement.textContent = '휴일';
          wrapperEl.appendChild(holidayTagElement);

          const isToday = this.moment().format('YYYY-MM-DD') === element.dataset.date;
          if (isToday) {
            const todayDot = document.createElement('div');
            todayDot.style.width = '8px';
            todayDot.style.height = '8px';
            todayDot.style.marginLeft = 'auto';
            todayDot.style.marginRight = '4px';
            todayDot.style.borderRadius = '50%';
            todayDot.style.backgroundColor = 'red';
            wrapperEl.appendChild(todayDot);
          }

          wrapperEl.appendChild(dayNumberElement);
          element.appendChild(wrapperEl);
        });
      }
    },

    viewSkeletonRender() {
      this.checkCurrentTime();
    },

    /** 현재 시간 표시 */
    checkCurrentTime() {
      const now = this.moment()
        .startOf('hour')
        .format('HH:mm:ss');
      const timeSlotElements = document.querySelectorAll('.fc-slats tr[data-time] span');
      const currentTimeSlotElement = document.querySelector(`.fc-slats tr[data-time='${now}'] span`);
      timeSlotElements.forEach(element => (element.style.color = '#646464'));
      if (currentTimeSlotElement) currentTimeSlotElement.style.color = '#64aeff';
    },

    /** 일정 변경/복사 */
    async updateLecture(eventActionType, event, oldEvent, revert, oldResource, newResource, revertOnSuccess = false) {
      // 기타일정은 일정 변경 못하게 방지
      if (event.extendedProps.type === 'etcSchedule') {
        return revert();
      }
      const { moment, $api, resourceType, $filters } = this;
      const { type, courseType, instructorId, roomId } = event.extendedProps;
      const eventCopyable = courseType === 'P' && eventActionType === 'drop';
      this.$utils.removePopovers();

      /** 권한 체크 */
      if (type === 'lecture') {
        if (!this.canUpdateLecture(courseType, instructorId)) {
          revert();
          return this.$utils.notify.error(this, '오류', '권한이 없습니다.');
        } else if (moment(oldEvent.start).isBefore(moment())) {
          revert();
          return this.$utils.notify.error(this, '오류', '시작 시간이 지난 수업은 일정을 변경 할 수 없습니다.');
        }
      } else if (type === 'counsel') {
        if (!this.canUpdateCounsel) {
          revert();
          return this.$utils.notify.error(this, '오류', '권한이 없습니다.');
        }
      }

      const title = type === 'lecture' ? '수업 일정' : '상담 일정';
      let message = '';
      let data = { [type]: {} };

      const oldStart = moment(oldEvent.start).format('YYYY-MM-DD HH:mm');
      const oldEnd = moment(oldEvent.end).format('YYYY-MM-DD HH:mm');
      const newStart = moment(event.start).format('YYYY-MM-DD HH:mm');
      const newEnd = moment(event.end).format('YYYY-MM-DD HH:mm');

      data[type] = {
        id: event.id,
        start_on: `${newStart}:00`,
        end_on: `${newEnd}:00`,
      };
      if (type === 'lecture') data[type].room_id = roomId || -1;
      if (oldStart !== newStart || oldEnd !== newEnd) {
        message += `
            일시:&ensp;<b>${$filters.datetime(oldStart)} ~ ${$filters.datetime(oldEnd)}</b><br>
            &ensp;&ensp;&ensp;&ensp;&ensp;⟶&ensp;<b>${$filters.datetime(newStart)} ~ ${$filters.datetime(newEnd)}</b><br>
          `;
      }

      if (oldResource && newResource && oldResource.id !== newResource.id) {
        if (type !== 'counsel' || resourceType !== 'room') {
          const label = resourceType === 'instructor' ? '강사' : '룸';
          const key = type === 'lecture' ? `${resourceType}_id` : 'staff_id';
          message += `${label}:&ensp;<b>${oldResource.title}</b>&ensp;⟶&ensp;<b>${newResource.title}</b><br>`;
          data[type][key] = newResource.id === 'null' ? -1 : newResource.id;
        }
      }

      /** 메시지가 없을 경우 변경 내용이 없는 것으로 간주 -> 종료 */
      if (!message) return revert();

      let cancelButtonText = '취소';
      let distinguishCancelAndClose = false;
      if (eventCopyable) {
        cancelButtonText = '복사';
        message += '<br><b>복사</b> 하시겠습니까? <b>변경</b> 하시겠습니까?';
        distinguishCancelAndClose = true;
        this.pressedESC = false;
      }

      const action = await this.$confirm(message, `${title} 변경`, {
        confirmButtonText: '변경',
        cancelButtonText,
        dangerouslyUseHTMLString: true,
        distinguishCancelAndClose,
      })
        .then(() => 'update')
        .catch(res => {
          if (eventCopyable && !this.pressedESC && res === 'cancel') return 'copy';
          return false;
        });
      this.pressedESC = false;
      if (!action) return revert();

      try {
        /** 일정 복사 */
        if (action === 'copy') {
          const course = {
            active: 1,
            coupon_deduction: 1,
            end_date: moment(newStart).format('YYYY-MM-DD'),
            instructor_id: _.get(event, 'extendedProps.lecture.staff.id', null),
            lectures: [{ ..._.pick(data.lecture, 'start_on', 'end_on') }],
            max_trainee: _.get(event, 'extendedProps.lecture.max_trainee'),
            min_trainee: _.get(event, 'extendedProps.lecture.max_trainee'),
            start_date: moment(newStart).format('YYYY-MM-DD'),
            title: _.get(event, 'extendedProps.lecture.title'),
            type: 'P',
            room_id: _.get(event, 'extendedProps.lecture.room.id', null),
          };
          const userTicketIds = event.extendedProps.lecture.bookings.map(booking => booking.userTicket.id).join(',');
          await $api.course.createAndBook(userTicketIds, course);
          this.$utils.notify.success(this, '확인', `${title}이 복사되었습니다.`);
          /** 일정 변경 */
        } else {
          if (type === 'lecture') {
            await $api.lecture.update(data[type]);
          } else if (type === 'counsel') {
            await $api.counsel.update(data[type]);
          }
          this.$utils.notify.success(this, '확인', `${title}을 변경했습니다.`);
        }

        if (revertOnSuccess) {
          revert();
        } else {
          this.$store.dispatch('schedule/getEvents');
        }
      } catch (error) {
        this.$utils.notify.parseError(this, error);
        revert();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#calendar-wrapper {
  height: calc(100vh - 240px);
  padding: 10px 0;
  position: relative;
}
</style>
