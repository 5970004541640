import _ from 'lodash';

/**
 * 일정 페이지에 사용할 이벤트 데이터 맵핑
 * -> 수업, 기타일정, 상담일정을 병합함
 */

export default (lectures = [], counsels = [], etcSchedules = [], currentUser) => {
  let events = [];
  lectures.forEach(lecture => {
    events.push({
      id: lecture.id,
      start: lecture.start_on,
      end: lecture.end_on,
      type: 'lecture',
      borderColor: _.get(lecture, 'staff.representative_color') || '000',
      currentTraineeCount: lecture.current_trainee_count,
      maxTraineeCount: lecture.max_trainee,
      lectureTitle: lecture.title,
      courseType: lecture.type,
      instructor: _.get(lecture, 'staff.name'),
      instructorId: _.get(lecture, 'staff.id'),
      bookings: lecture.bookings,
      roomId: _.get(lecture, 'room.id'),
      roomName: _.get(lecture, 'room.name'),
      lecture,
    });
  });
  counsels.forEach(counsel => {
    if (counsel.channel === 'visit') {
      events.push({
        id: counsel.id,
        start: counsel.start_on,
        end: counsel.end_on,
        type: 'counsel',
        borderColor: _.get(counsel, 'staff.representative_color') || '000',
        name: counsel.name,
        contents: counsel.contents,
        instructor: counsel.staff.name,
        instructorId: counsel.staff.id,
        roomId: null,
        roomName: null,
        phone: counsel.phone,
      });
    }
  });
  etcSchedules.forEach(etc => {
    const includedStaffIds = etc.staffs.map(staff => staff.id);
    const hasCurrentUser = includedStaffIds.includes(currentUser.id);
    const instructor = !etc.staffs.length
      ? '강사'
      : etc.staffs.length === 1
      ? `${etc.staffs[0].name}`
      : `${hasCurrentUser ? currentUser.name : etc.staffs[0].name} 외 ${etc.staffs.length - 1}명`;
    const instructorId = !etc.staffs.length ? 0 : includedStaffIds;

    events.push({
      id: etc.id,
      start: etc.start_on,
      end: etc.end_on,
      type: 'etcSchedule',
      borderColor: _.get(etc, 'color') || '000',
      etcTitle: etc.title,
      instructor,
      instructorId,
      contents: etc.title,
      roomId: null,
      roomName: null,
      phone: null,
      isPrivate: etc.is_private,
    });
  });
  return events;
};
