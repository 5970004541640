<template>
  <MainLayout :padded="false" v-loading="loading">
    <content-header :breadcrumb="breadcrumb" :contentMaxWidth="1131">
      <div class="content-title">
        <div class="content-title__profile-image">
          <div>
            <img :src="profileImageUrl" :alt="memberName" />
            <span v-if="member.vaccination_yn === 'Y'">백신 접종 완료!</span>
          </div>
        </div>
        <div class="content-title__member-detail">
          <div class="content-title__member-detail__name">
            <h3>{{ memberName }}</h3>
            <span v-if="useUserGrades && memberGrade" :style="{ 'background-color': `#${memberGradeColor}` }">{{
              memberGrade
            }}</span>
          </div>
          <p class="content-title__member-detail__vaccine" v-if="member.vaccination_yn === 'Y'">백신 접종 완료!</p>
          <p>등록일: {{ registeredAt | dateKorean }}</p>
          <div v-if="member.lockers && member.lockers.length" class="content-title__member-detail__locker">
            <span>락커: </span>
            <span
              v-for="locker in member.lockers"
              :key="locker.id"
              class="content-title__member-detail__locker__lockers"
              @click="$router.push(`/locker?lockerId=${locker.id}`)"
            >
              <span>{{ locker.no }}. </span>
              <span>{{ locker.name }} </span>
              <span class="content-title__member-detail__locker__date-range"
                >{{ locker.start_on | date }} ~ {{ locker.end_on | date }}
              </span>
            </span>
          </div>
          <p class="content-title__member-detail__mobile">
            <span>{{ memberMobile }}</span>
            <span v-if="appLinked" class="content-title__member-detail__app-link">
              <img src="@/assets/images/01_graphic/ic_16x16/solid/app-active.png" alt="앱연결" />
              앱 연결
            </span>
          </p>
          <p v-if="!!gender || !!birthday" class="content-title__member-detail__multi">
            <span v-if="!!gender">{{ gender }}</span>
            <span v-if="!!birthday">{{ birthday | dateKorean }}</span>
          </p>
          <p v-if="address">{{ address }}</p>
        </div>
      </div>

      <div class="member-detail__header-controls">
        <CustomTabs
          class="member-detail__header-controls__tabs"
          :active_tab="currentTab"
          :tabs="filteredTabs"
          :handleChangeTab="handleChangeTab"
        />

        <div class="member-detail__header-controls__buttons">
          <PlainButton v-if="memberMobile" :disabled="!canSendAnyMessage" @click="showMessageDialog = true" round
            >메시지 보내기</PlainButton
          >
          <PlainButton v-else disabled round>메시지 보내기</PlainButton>
          <PlainButton @click="handleClickBulkBookings" :disabled="!userTicketsActive.length" round>일괄예약</PlainButton>
          <PlainButton v-if="canUpdateMembers" @click="$router.push(`/users/create?id=${member.id}`)" round
            >회원정보 수정</PlainButton
          >
        </div>
      </div>
    </content-header>

    <BasicTab v-if="currentTab === 'basic'" class="member-detail__body" />
    <HistoryTab v-else-if="currentTab === 'history'" class="member-detail__body" />
    <PointHistoryTab v-else-if="currentTab === 'pointHistory'" class="member-detail__body" />

    <el-dialog class="message-modal" :visible.sync="showMessageDialog">
      <CustomTabs
        class="message-modal__tabs"
        :active_tab="messageTypeTab"
        :tabs="messageTabs"
        :handleChangeTab="handleMessageTab"
      />
      <MessageForm
        v-if="showMessageDialog"
        hide-member-list-button
        is-on-modal
        :members="[member]"
        :messageType="messageTypeTab"
        @messageSent="showMessageDialog = false"
      />
    </el-dialog>
  </MainLayout>
</template>

<script>
import BasicTab from '@/components/MemberDetail/Basic';
import HistoryTab from '@/components/MemberDetail/History';
import PointHistoryTab from '@/components/MemberDetail/PointHistory';
import MessageForm from '@/components/Messages/Form';

export default {
  components: {
    BasicTab,
    HistoryTab,
    PointHistoryTab,
    MessageForm,
  },

  data() {
    return {
      loading: false,

      breadcrumb: {
        parent: { path: '/users', label: '회원' },
        current_route_label: '회원 정보',
      },

      messageTypeTab: 'sms',

      currentTab: 'basic',
      tabs: [
        { value: 'basic', label: '기본정보' },
        { value: 'history', label: '이용내역' },
        { value: 'pointHistory', label: '포인트 내역' },
      ],

      showMessageDialog: false,
    };
  },

  computed: {
    member() {
      return this.$store.getters['member/member'];
    },
    userTicketsActive() {
      return this.$store.getters['member/userTicketsActive'];
    },
    filteredTabs() {
      return this.tabs.filter(tab => this.canUpdateMembersTicket || tab.value !== 'pointHistory');
    },
    profileImageUrl() {
      if (this.member.avatars && this.member.avatars.length) {
        return this.$utils.getImageUrl(_.get(this.member, 'avatars[0]'), '148x148');
      }
      return this.$utils.getImageUrl(_.get(this.member, 'account_avatar'), '148x148');
    },

    memberName() {
      return _.get(this.member, 'profile.name');
    },

    useUserGrades() {
      return !!_.get(this.studioPolicies, 'is_use_user_grade');
    },

    memberGrade() {
      return _.get(this.member, 'user_grade.name');
    },
    memberGradeColor() {
      return _.get(this.member, 'user_grade.representative_color');
    },
    memberMobile() {
      const mobile = _.get(this.member, 'mobile');
      if (!mobile) return null;

      return this.canViewMembersMobile ? this.$filters.mobile(mobile) : this.$filters.mobileMask(mobile);
    },

    registeredAt() {
      return _.get(this.member, 'profile.registered_at');
    },

    gender() {
      if (!this.member.profile) return '';
      const gender = this.member.profile.gender === 'M' ? '남성' : this.member.profile.gender === 'F' ? '여성' : '';
      return gender;
    },

    birthday() {
      return _.get(this.member, 'profile.birthday');
    },

    address() {
      if (!this.member.address) return '';

      const { address, detail_address } = this.member.address;
      if (!address && !detail_address) return '';

      let fullAddress = '';
      if (address) fullAddress = `${address} `;
      if (detail_address) fullAddress += detail_address;
      return `주소: ${fullAddress}`;
    },

    appLinked() {
      return !!this.member.account_id;
    },

    canSendAnyMessage() {
      return this.canSendSmsMessage || (this.appLinked && this.canSendAppPushMessage);
    },

    messageTabs() {
      let tabs = [{ value: 'sms', label: 'SMS 보내기', disabled: !this.canSendSmsMessage }];
      if (this.appLinked) {
        tabs.push({ value: 'message', label: '앱푸시 보내기', disabled: !this.canSendAppPushMessage });
      }
      return tabs;
    },
  },

  watch: {
    $route: {
      handler: 'setInitialValues',
      immediate: true,
    },
  },

  beforeRouteEnter(to, from, next) {
    if (!to.query.id) return next('/users');

    next(vm => {
      if (!vm.canViewMembers) {
        vm.$utils.notify.error(vm, '오류', '권한이 없습니다.');
        return next('/users');
      }
      next();
    });
  },

  created() {
    if (!this.canSendSmsMessage) {
      this.messageTypeTab = 'message';
    }
  },

  destroyed() {
    this.$store.commit('member/RESET_BOOKING_HISTORY_PARAMS');
  },

  methods: {
    async setInitialValues() {
      const { id, tab } = this.$route.query;

      this.loading = true;
      const memberId = await this.$store.dispatch('member/getMember', id);
      this.loading = false;
      if (!memberId) {
        this.$utils.notify.error(this, '오류', '회원 정보를 가져오는데 실패했습니다.');
        return this.$router.push('/users');
      }
      this.$store.dispatch('member/getUserTickets', memberId);
      this.$store.dispatch('member/getBookedLectures', memberId);
      this.$store.dispatch('member/getMemos', memberId);
      this.$store.dispatch('member/getBookingHistory', memberId);

      if (tab) this.handleChangeTab(tab);
    },

    handleChangeTab(tab) {
      this.currentTab = tab;
    },
    handleMessageTab(tab) {
      this.messageTypeTab = tab;
    },

    handleClickBulkBookings() {
      this.$router.push(`/users/${this.member.id}/bulk_bookings`);
    },
  },
};
</script>

<style lang="scss" scoped>
.content-title {
  width: 100%;

  @include mq(568px) {
    @include flex(row-reverse);
  }

  &__profile-image {
    @include flex(row, center, center);
    @include mq(568px) {
      margin-left: 20px;
    }

    div {
      position: relative;
    }

    img {
      border: solid 1px rgba(0, 0, 0, 0.05);
      border-radius: 50%;
      width: 148px;
      height: 148px;
    }

    span {
      font-size: 0;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 57px;
      height: 57px;
      background: url('../../assets/images/icons/vaccine-member-profile.svg') 0 0 no-repeat;
      background-size: cover;
    }
  }

  &__member-detail {
    @include flex(column);
    @include mq(568px) {
      flex: 1;
    }

    &__name {
      @include flex(row, center, space-between);
      margin-bottom: 12px;

      @include mq(568px) {
        justify-content: flex-start;
        flex-wrap: wrap;
      }

      h3 {
        font-size: 38px !important;
        font-weight: bold;
        margin: 0 !important;
      }

      span {
        background: #757575;
        color: #fff;
        font-size: 11px;
        padding: 0 8px;
        border-radius: 4px;
        margin-left: 40px;
      }
    }

    .content-title__member-detail__vaccine {
      font-size: 14px;
      color: #4a8eff;
      padding-left: 37px;
      margin-bottom: 15px;
      position: relative;

      &:before {
        content: '';
        width: 30px;
        height: 30px;
        position: absolute;
        top: -4px;
        left: 0;
        background: url('../../assets/images/icons/vaccine-member-detail.png') 0 0 no-repeat;
        background-size: cover;
      }
    }

    &__locker {
      margin-bottom: 8px;
      span {
        color: $gray-600;
        font-size: 16px;
      }
      &__date-range {
        font-size: 11px;
        padding: 0 8px;
        border-radius: 4px;
        background-color: rgba(144, 147, 153, 0.1);
        border: 1px solid rgba(144, 147, 153, 0.2);
      }
      &__date-range:hover {
        text-decoration: none !important;
      }
      &__lockers {
        margin-right: 10px;
      }
      &__lockers:hover {
        cursor: pointer;
      }
    }

    p {
      @include flex(column);
      color: $gray-600;
      font-size: 16px;
      margin-bottom: 8px;

      @include mq(768px) {
        @include flex(row, center, flex-start);
        flex-wrap: wrap;
      }
    }

    &__mobile {
      flex-direction: row !important;
    }

    &__multi {
      @include mq(768px) {
        span + span:before {
          content: '·';
          margin: 0 10px;
        }
      }
    }

    &__app-link {
      @include flex(row, center, center);
      font-size: 14px;
      font-weight: bold;
      color: $deep-sky-blue;
      width: auto;
      margin-left: 30px;
    }
  }
}

.member-detail__header-controls {
  @include flex(column-reverse, flex-start);
  width: 100%;

  @include mq(768px) {
    @include flex(row, center, space-between);
  }

  &__tabs {
    transform: translateY(25px);

    @include mq(768px) {
      transform: translateY(37px);
    }
  }

  &__buttons {
    @include flex(row, center, flex-end);
    flex-wrap: wrap;

    button {
      margin-left: 8px;

      &:first-child {
        margin-left: 0;
      }
    }

    @include mq(768px) {
      @include flex(row, center, flex-end);

      button {
        margin-left: 16px;
      }
    }
  }
}

.member-detail__body {
  @include flex(column);
  padding: 20px;
  max-width: 1131px;
  margin: auto;

  @include mq(768px) {
    padding: 30px 40px;
  }
}

.message-modal {
  /deep/ .el-dialog__header {
    padding: 0;
  }
  /deep/ .el-dialog__body {
    padding: 10px 20px 30px 20px;
  }

  &__tabs {
    justify-content: flex-start;
    padding-bottom: 30px;
  }
}
</style>
