<template>
  <el-popover trigger="manual" :visible-arrow="false" placement="right-start" v-model="showPopover">
    <div
      v-if="viewType === 'month'"
      slot="reference"
      class="event-item month"
      @mouseenter="onMouseEnter"
      @mouseleave="onMouseLeave"
      :style="eventItemStyle"
    >
      <span class="event-item__subtitle">{{ eventStartOn }}</span
      ><span v-if="event.extendedProps.isPrivate" class="event-item__lockIcon">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8 2C6.2511 2 4.83334 3.43773 4.83334 5.21127V6.62727C3.97072 6.85242 3.33334 7.6465 3.33334 8.59154V11.9718C3.33334 13.092 4.22877 14 5.33334 14H10.6667C11.7712 14 12.6667 13.092 12.6667 11.9718V8.59154C12.6667 7.6465 12.0293 6.85242 11.1667 6.62727V5.21127C11.1667 3.43773 9.7489 2 8 2ZM10.1667 6.56337V5.21127C10.1667 3.9978 9.19662 3.01408 8 3.01408C6.80339 3.01408 5.83334 3.9978 5.83334 5.21127V6.56337H10.1667ZM4.33334 8.59154C4.33334 8.03148 4.78105 7.57746 5.33334 7.57746H10.6667C11.219 7.57746 11.6667 8.03148 11.6667 8.59154V11.9718C11.6667 12.5319 11.219 12.9859 10.6667 12.9859H5.33334C4.78105 12.9859 4.33334 12.5319 4.33334 11.9718V8.59154ZM8.5 10.3908V11.9718H7.5V10.3908C7.39628 10.2717 7.33334 10.1151 7.33334 9.94366C7.33334 9.57028 7.63181 9.2676 8 9.2676C8.36819 9.2676 8.66667 9.57028 8.66667 9.94366C8.66667 10.1151 8.60373 10.2717 8.5 10.3908Z"
            fill="#777777"
          />
        </svg>
      </span>
      <span class="event-item__title">{{ monthEventTitle }}</span>
      <span class="event-item__subtitle">{{ traineeCount }}</span>
    </div>

    <div
      v-else
      slot="reference"
      :class="event.extendedProps.isPrivate ? 'event-item etc' : 'event-item'"
      @mouseenter="onMouseEnter"
      @mouseleave="onMouseLeave"
      :style="{ ...eventItemStyle, opacity: this.isPast ? '0.3' : '1' }"
    >
      <div class="event-item__title-wrapper">
        <span v-if="event.extendedProps.isPrivate" class="event-item__lockIcon">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="lock_bold">
              <path
                id="Union"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10 2.5C7.81387 2.5 6.04166 4.29717 6.04166 6.51408V8.28409C4.9634 8.56552 4.16666 9.55812 4.16666 10.7394V14.9648C4.16666 16.3649 5.28595 17.5 6.66666 17.5H13.3333C14.714 17.5 15.8333 16.3649 15.8333 14.9648V10.7394C15.8333 9.55812 15.0366 8.56552 13.9583 8.28409V6.51408C13.9583 4.29717 12.1861 2.5 10 2.5ZM12.7083 8.20422V6.51408C12.7083 4.99725 11.4958 3.76761 10 3.76761C8.50423 3.76761 7.29166 4.99725 7.29166 6.51408V8.20422H12.7083ZM5.41666 10.7394C5.41666 10.0394 5.97631 9.47182 6.66666 9.47182H13.3333C14.0237 9.47182 14.5833 10.0394 14.5833 10.7394V14.9648C14.5833 15.6649 14.0237 16.2324 13.3333 16.2324H6.66666C5.97631 16.2324 5.41666 15.6649 5.41666 14.9648V10.7394ZM10.625 12.9885V14.9648H9.375V12.9885C9.24534 12.8396 9.16666 12.6439 9.16666 12.4296C9.16666 11.9628 9.53976 11.5845 10 11.5845C10.4602 11.5845 10.8333 11.9628 10.8333 12.4296C10.8333 12.6439 10.7547 12.8396 10.625 12.9885Z"
                fill="#777777"
              />
            </g>
          </svg>
        </span>
        <span class="event-item__title"> {{ eventTitle }}</span>
      </div>
      <span v-if="event.extendedProps.courseType === 'G'" class="event-item__subtitle">{{ eventStartOn }}</span>
      <span
        v-if="event.extendedProps.type === 'counsel' || event.extendedProps.type === 'etcSchedule'"
        ref="counselTag"
        class="event-item__tag"
        >{{ event.extendedProps.type === 'counsel' ? '상담' : '기타' }}</span
      >
      <span class="event-item__subtitle full-width">{{
        event.extendedProps.courseType === 'G' ? event.extendedProps.lectureTitle : eventStartOn
      }}</span>
    </div>

    <div :class="event.extendedProps.type === 'etcSchedule' ? 'event-item-popover etc' : 'event-item-popover'">
      <header class="event-item-popover__header" :style="{ backgroundColor }">
        <div class="event-item-popover__header__first-row">
          <span>{{ eventDate }}</span>
          <span>{{ `${eventStartOn}~${eventEndOn}` }}</span>
          <span v-if="event.extendedProps.type === 'lecture'">{{ traineeCount }}</span>
        </div>
        <div class="event-item-popover__header__second-row" v-if="event.extendedProps.type !== 'etcSchedule'">
          <span class="event-item-popover__course-title">{{ event.extendedProps.lectureTitle || eventTitle }}</span>
          <span class="event-item-popover__type-tag">{{ eventType }}</span>
        </div>
        <div
          :class="
            event.extendedProps.type === 'lecture'
              ? 'event-item-popover__header__third-row'
              : 'event-item-popover__header__third-row no-ellipsis'
          "
        >
          <span class="event-item-popover__instructor-name">
            <span :class="event.extendedProps.type === 'etcSchedule' ? 'no-ellipsis' : ''">{{
              event.extendedProps.instructor
            }}</span>
            <span v-if="event.extendedProps.type !== 'etcSchedule'">강사</span>
          </span>
          <span class="event-item-popover__type-tag" v-if="event.extendedProps.type === 'etcSchedule'">{{ eventType }}</span>
          <span v-if="event.extendedProps.type === 'lecture'" class="event-item-popover__room-name">{{ roomName }}</span>
          <span v-if="bookingClosed" class="event-item-popover__booking-closed">예약마감</span>
        </div>
      </header>
      <section class="event-item-popover__body">
        <p v-if="!bookingsShouldRender.length && !event.extendedProps.contents">예약 회원이 없습니다.</p>
        <p
          v-else-if="!!event.extendedProps.contents"
          ref="counsel_contents"
          class="event-item-popover__counsel_contents"
          v-html="event.extendedProps.contents"
        />
        <span v-if="event.extendedProps.isPrivate" class="event-item__lockIcon">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8 2C6.2511 2 4.83334 3.43773 4.83334 5.21127V6.62727C3.97072 6.85242 3.33334 7.6465 3.33334 8.59154V11.9718C3.33334 13.092 4.22877 14 5.33334 14H10.6667C11.7712 14 12.6667 13.092 12.6667 11.9718V8.59154C12.6667 7.6465 12.0293 6.85242 11.1667 6.62727V5.21127C11.1667 3.43773 9.7489 2 8 2ZM10.1667 6.56337V5.21127C10.1667 3.9978 9.19662 3.01408 8 3.01408C6.80339 3.01408 5.83334 3.9978 5.83334 5.21127V6.56337H10.1667ZM4.33334 8.59154C4.33334 8.03148 4.78105 7.57746 5.33334 7.57746H10.6667C11.219 7.57746 11.6667 8.03148 11.6667 8.59154V11.9718C11.6667 12.5319 11.219 12.9859 10.6667 12.9859H5.33334C4.78105 12.9859 4.33334 12.5319 4.33334 11.9718V8.59154ZM8.5 10.3908V11.9718H7.5V10.3908C7.39628 10.2717 7.33334 10.1151 7.33334 9.94366C7.33334 9.57028 7.63181 9.2676 8 9.2676C8.36819 9.2676 8.66667 9.57028 8.66667 9.94366C8.66667 10.1151 8.60373 10.2717 8.5 10.3908Z"
              fill="#777777"
            />
          </svg>
        </span>
        <ul v-else v-for="(key, index) in bookingsShouldRender" :key="index">
          <li>
            <span class="event-item-popover__list_title">{{ getListTitle(key) }}</span>
          </li>
          <li v-for="booking in bookings[key].slice(0, 10)" :key="booking.id">
            <div class="event-item-popover__member_name_wrapper">
              <span class="event-item-popover__member_name">{{ booking.memberName }}</span>
              <img
                v-if="booking.vaccination"
                class="vaccine"
                src="@/assets/images/icons/vaccine.svg"
                alt="백신 접종 완료 후 14일 경과"
              />
            </div>
            <span class="event-item-popover__ticket_title">{{ booking.ticketTitle }}</span>
            <span class="event-item-popover__remaining_days">{{ booking.remainingDays }}</span>
            <span class="event-item-popover__remaining_ticket_count">{{ booking.remainingTicketCount }}</span>
          </li>
          <li v-if="bookings.length > 10" class="event-item-popover__more">{{ `... 외 ${bookings.length - 10}명` }}</li>
        </ul>

        <!-- 자동 취소된 예약 대기 회원 목록 -->
        <div v-if="!!autoCancelBookings.length && showDeletedBookingWaitingList">
          <hr />
          <ul>
            <li>
              <span class="event-item-popover__list_title">{{ getAutoCancelListTitle() }}</span>
            </li>
            <li v-for="(booking, index) in autoCancelBookings" :key="index">
              <div class="event-item-popover__member_name_wrapper">
                <span class="event-item-popover__member_name">{{ booking.memberName }}</span>
                <img
                  v-if="booking.vaccination"
                  class="vaccine"
                  src="@/assets/images/icons/vaccine.svg"
                  alt="백신 접종 완료 후 14일 경과"
                />
              </div>
              <span class="event-item-popover__ticket_title">{{ booking.ticketTitle }}</span>
              <span class="event-item-popover__remaining_days">{{ booking.remainingDays }}</span>
              <span class="event-item-popover__remaining_ticket_count">{{ booking.remainingTicketCount }}</span>
            </li>
            <li v-if="autoCancelBookings.length > 10" class="event-item-popover__more">
              {{ `... 외 ${autoCancelBookings.length - 10}명` }}
            </li>
          </ul>
        </div>
      </section>
    </div>
  </el-popover>
</template>

<script>
import { BOOKING_STATUS } from '@constants';

export default {
  props: {
    event: { type: Object, required: true },
    viewType: { type: String },
    isShowBackGroundColor: { type: Object },
  },

  data() {
    return {
      showPopover: false,
    };
  },

  computed: {
    eventItemStyle() {
      let styles = { borderColor: this.eventColor };
      const { courseType, currentTraineeCount, maxTraineeCount } = this.event.extendedProps;

      if (
        (courseType === 'P' && this.isShowBackGroundColor.private) ||
        (courseType === 'G' && this.isShowBackGroundColor.group && (this.bookingClosed || currentTraineeCount >= maxTraineeCount))
      ) {
        styles = { ...styles, backgroundColor: this.eventColor, color: '#fff' };
      }

      return styles;
    },

    eventTitle() {
      if (!this.event) return '';

      const { type, courseType, lectureTitle, etcTitle, name } = this.event.extendedProps;

      if (courseType === 'G') {
        return this.traineeCount;
      } else if (courseType === 'P') {
        return lectureTitle;
      } else if (type === 'counsel') {
        return `${name}님`;
      } else if (type === 'etcSchedule') {
        return etcTitle;
      }
      return null;
    },

    monthEventTitle() {
      const { type, lectureTitle, etcTitle, name } = this.event.extendedProps;

      if (type === 'counsel') {
        return `${name}님`;
      } else if (type === 'etcSchedule') {
        return etcTitle;
      } else {
        return lectureTitle;
      }
    },

    eventDate() {
      if (!this.event) return '';
      return this.moment(this.event.start).format('YYYY. M. D. (ddd)');
    },

    eventStartOn() {
      if (!this.event) return '';
      return this.moment(this.event.start).format('HH:mm');
    },

    eventEndOn() {
      if (!this.event) return '';
      return this.moment(this.event.end).format('HH:mm');
    },

    isPast() {
      if (!this.event) return false;
      return this.moment(this.event.start).isBefore(this.moment().startOf('day'));
    },

    eventColor() {
      if (!this.event) return '#000';
      return `#${this.event.borderColor || '000'}`;
    },

    backgroundColor() {
      if (!this.event) return '#fff';
      const { courseType, currentTraineeCount, maxTraineeCount } = this.event.extendedProps;

      if (
        (courseType === 'P' && this.isShowBackGroundColor.private) ||
        (courseType === 'G' && this.isShowBackGroundColor.group && (this.bookingClosed || currentTraineeCount >= maxTraineeCount))
      ) {
        return this.eventColor;
      }

      const color = this.event.borderColor;
      return `#${color || 'fff'}`;
    },

    eventType() {
      if (!this.event) return '';

      const { type, courseType } = this.event.extendedProps;
      if (type === 'lecture') {
        return `${this.$utils.translate.courseType(courseType)}수업`;
      } else if (type === 'counsel') {
        return '상담';
      } else if (type === 'etcSchedule') {
        return '기타';
      }

      return null;
    },

    roomName() {
      const roomId = _.get(this.event, 'extendedProps.roomId');
      const roomName = _.get(this.event, 'extendedProps.roomName');
      return roomId ? `${roomName}룸` : null;
    },

    bookingClosed() {
      const bookingClosedAt = _.get(this.event, 'extendedProps.lecture.booking_closed_at');
      return !!bookingClosedAt;
    },

    traineeCount() {
      if (!this.event) return '';
      const { type, courseType, currentTraineeCount, maxTraineeCount } = this.event.extendedProps;

      if (type === 'counsel') return '상담';
      if (type === 'etcSchedule') return '기타';

      const PRIVATE_LABEL = { 1: '개인', 2: '듀엣', 3: '트리플' };

      return courseType === 'P' ? PRIVATE_LABEL[maxTraineeCount] : `${currentTraineeCount}/${maxTraineeCount}`;
    },

    bookings() {
      let bookings = {
        [BOOKING_STATUS.BOOKED]: [],
        [BOOKING_STATUS.WAITING]: [],
        [BOOKING_STATUS.ATTENDED]: [],
        [BOOKING_STATUS.ABSENT]: [],
        [BOOKING_STATUS.NOSHOW]: [],
      };
      if (!this.event || this.event.extendedProps.type === 'counsel' || this.event.extendedProps.type === 'etcSchedule')
        return bookings;

      this.event.extendedProps.bookings
        .filter(({ status }) => status !== BOOKING_STATUS.CANCEL)
        .forEach(({ status, member, userTicket }) => {
          const remainingDays = this.$utils.getRemainingDaysText(userTicket.expire_at, userTicket.availability_start_at);
          const remainingTicketCount = `잔여 ${userTicket.remaining_coupon}/${userTicket.max_coupon}`;
          const data = {
            memberName: !member.is_del ? member.name : '[삭제된 회원]',
            ticketTitle: userTicket.ticket.title,
            vaccination: member.vaccination_yn === 'Y' ? true : false,
            remainingDays,
            remainingTicketCount: userTicket.ticket.type === 'P' ? null : remainingTicketCount,
          };

          if ([BOOKING_STATUS.BOOKED, BOOKING_STATUS.CONFIRMED].includes(status)) {
            bookings.booked.push(data);
          } else if (status) {
            bookings[status].push(data);
          }
        });
      return bookings;
    },

    bookingsShouldRender() {
      return [
        BOOKING_STATUS.BOOKED,
        BOOKING_STATUS.ATTENDED,
        BOOKING_STATUS.ABSENT,
        BOOKING_STATUS.NOSHOW,
        BOOKING_STATUS.WAITING,
      ].filter(key => this.bookings[key].length > 0);
    },

    // 자동 예약 시간이 지나지 않았을 때
    // 회원들이 전부 출석/결석/노쇼일 때,

    autoCancelBookings() {
      const bookings = this.event.extendedProps.bookings || [];

      const autoCancelBookings = bookings.filter(({ updated_for }) => updated_for === '수업에 결원이 발생하지 않아 취소');

      return autoCancelBookings.map(booking => {
        const { userTicket, member } = booking;
        const remainingDays = this.$utils.getRemainingDaysText(userTicket.expire_at, userTicket.availability_start_at);
        const remainingTicketCount = `잔여 ${userTicket.remaining_coupon}/${userTicket.max_coupon}`;

        return {
          memberName: member.name,
          ticketTitle: userTicket.ticket.title,
          vaccination: member.vaccination_yn === 'Y' ? true : false,
          remainingDays,
          remainingTicketCount: userTicket.ticket.type === 'P' ? null : remainingTicketCount,
        };
      });
    },

    /** 자동 취소된 예약 대기 명단 표시: 수업 종료 시간 전까지 */
    showDeletedBookingWaitingList() {
      const lecture = this.event.extendedProps.lecture;
      const isLectureDone = this.moment().isAfter(lecture.end_on);
      const hasDeletedBookingWaitingList = this.autoCancelBookings.length > 0;
      return !isLectureDone && hasDeletedBookingWaitingList;
    },
  },

  methods: {
    onMouseEnter(e) {
      const el = e.target;
      el.style.backgroundColor = this.backgroundColor;
      el.style.color = '#fff';
      const tagEl = this.$refs.counselTag;
      if (tagEl) tagEl.style.color = this.backgroundColor;

      // 비공개 자물쇠 SVG fill 색상 변경
      const lockIcon = el.querySelector('.event-item__lockIcon path');
      if (lockIcon) lockIcon.setAttribute('fill', '#fff');

      this.showPopover = true;
      this.$utils.removePopovers();
      this.$nextTick(() => {
        const element = this.$refs.counsel_contents;
        if (element && element.clientHeight < element.scrollHeight) {
          element.classList.add('overflow');
        }
      });
    },

    onMouseLeave(e) {
      const el = e.target;
      const { courseType, currentTraineeCount, maxTraineeCount } = this.event.extendedProps;

      const changeColor =
        (courseType === 'P' && this.isShowBackGroundColor.private) ||
        (courseType === 'G' &&
          this.isShowBackGroundColor.group &&
          (this.bookingClosed || currentTraineeCount >= maxTraineeCount));

      el.style.backgroundColor = changeColor ? this.eventColor : '#fff';
      el.style.color = changeColor ? '#fff' : '#1a1a1a';

      const tagEl = this.$refs.counselTag;
      if (tagEl) tagEl.style.color = '#1a1a1a';

      // 비공개 자물쇠 SVG fill 색상 원래대로
      const lockIcon = el.querySelector('.event-item__lockIcon path');
      if (lockIcon) lockIcon.setAttribute('fill', '#777777');

      this.showPopover = false;
      this.$utils.removePopovers();
    },

    getListTitle(key) {
      const title = `${this.$utils.translate.bookingStatus(key)} 회원`;
      const count = `${this.bookings[key].length}명`;
      return `${title} (${count})`;
    },

    getAutoCancelListTitle() {
      const title = '자동 취소된 예약 대기 회원';
      const count = `${this.autoCancelBookings.length}명`;
      return `${title} (${count})`;
    },
  },
};
</script>

<style lang="scss" scoped>
.event-item {
  @include flex(row, center, space-between);
  flex-wrap: wrap;
  border: 2px solid #000;
  border-radius: 3px;
  background: #fff;
  color: #1a1a1a;
  height: 100%;
  padding: 2px 4px;
  overflow: hidden;
  box-sizing: border-box;
  transition: border 0.15s, background 0.15s, color 0.15s;

  .event-item__title-wrapper {
    display: flex;
    align-items: center;
  }

  svg {
    display: block;
  }

  &.month {
    flex-wrap: nowrap;
    border-width: 1px;
  }

  &__lockIcon {
    flex: 0 0 auto;
  }

  &__title {
    white-space: nowrap;
    font-size: 15px;
    font-weight: bold;
  }

  &.month &__lockIcon {
    svg {
      margin-left: 4px;
    }

    & + .event-item__title {
      margin: 0 4px 0 0;
    }
  }

  &.month &__title {
    @include ellipsis;
    flex: 1;
    font-size: 12px;
    margin: 0 4px;
  }

  &__subtitle {
    white-space: nowrap;
    font-size: 13px;

    &.full-width {
      width: 100%;
    }
  }

  &.month &__subtitle {
    font-size: 12px;
  }

  &__tag {
    background: #ddd;
    border-radius: 2px;
    font-size: 10px;
    font-weight: 400;
    padding: 2px;
  }

  &-popover {
    width: 95vw;
    max-width: 320px;
    overflow: auto;

    &.etc &__header {
      grid-template-rows: repeat(2, 18px);
    }

    &__header {
      display: grid;
      grid-template-rows: repeat(3, 18px);
      grid-row-gap: 8px;
      background: #000;
      border-radius: 2px 2px 0 0;
      color: #fff;
      padding: 8px;

      & > div {
        display: grid;
        grid-gap: 12px;
        grid-template-rows: 20px;
        align-items: center;
        width: 100%;
      }

      span {
        @include ellipsis;
        font-size: 12px;
      }

      &__first-row {
        grid-template-columns: 100px 1fr auto;
      }

      &__second-row {
        grid-template-columns: 1fr auto;
      }

      &__third-row {
        grid-template-columns: 100px 1fr auto;
        &.no-ellipsis {
          grid-template-columns: 1fr auto;
        }
      }
    }

    &__course-title {
      flex: 1;
      font-weight: bold;
      @include ellipsis;
    }

    &__type-tag,
    &__booking-closed {
      border: 1px solid #fff;
      border-radius: 2px;
      font-size: 10px;
      padding: 1px;
    }

    &__instructor-name {
      @include flex(row, center);
      gap: 4px;
      span:first-child {
        @include ellipsis;
        max-width: 60px;

        &.no-ellipsis {
          max-width: 100%;
        }
      }
    }

    &.etc &__body {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: flex-start;
      gap: 4px;
      padding: 8px;

      .event-item__lockIcon {
        margin-top: 13px;
      }
    }

    &__body {
      padding: 4px 8px;

      ul {
        padding: 16px 0;
      }

      ul + ul {
        border-top: 1px solid #d8d8d8;
      }

      & > p {
        text-align: center;
        padding: 12px 0;
      }

      li {
        display: grid;
        grid-template-columns: 80px 1fr 64px 76px;
        grid-gap: 4px;
        align-items: center;

        &.event-item-popover__more {
          display: block;
          font-size: 10px;
          margin-top: 4px;
        }
      }

      li + li {
        margin-top: 8px;
      }
    }

    &__counsel_contents {
      text-align: left !important;
      max-height: 200px;
      white-space: pre-line;
      overflow: hidden;

      &.overflow {
        margin-bottom: 16px;

        &::after {
          content: '...';
          position: absolute;
          bottom: 4px;
          left: 8px;
        }
      }
    }

    &__list_title {
      grid-column: 1 / 4;
      font-size: 12px;
      color: #343434;
      padding-bottom: 4px;
    }

    &__member_name_wrapper {
      display: flex;
      align-items: center;

      .event-item-popover__member_name {
        @include ellipsis;
        color: #000;
        font-size: 14px;
        max-width: 60px;
      }

      .vaccine {
        width: 12px;
        height: 12px;
        margin-left: 4px;
      }
    }

    &__ticket_title,
    &__remaining_days,
    &__remaining_ticket_count {
      @include ellipsis;
      font-size: 12px;
      color: #646464;
    }
  }
}

hr {
  margin: 0;
  border: 0;
  border-bottom: 1px solid #dcdcdc;
}
</style>
