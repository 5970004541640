import api from '@api';
import utils from '@utils';
import _ from 'lodash';
import moment from 'moment';

const getMonday = date => {
  let monday = moment(date).day(1);
  if (moment(date).day() === 0) monday = moment(date).subtract(6, 'd');
  return monday.format('YYYY-MM-DD');
};

const DEFAULT_DATES = {
  date: moment().format('YYYY-MM-DD'),
  week: getMonday(moment()),
  month: moment()
    .startOf('month')
    .format('YYYY-MM-DD'),
};

const DEFAULT_ROOMS = [
  {
    id: null,
    name: '룸 미설정',
  },
];
const DEFAULT_DISPLAY_HOURS = [
  {
    start: '00:00',
    end: '23:55',
  },
];
const DEFAULT_DISPLAY_WEEKDAYS = ['월', '화', '수', '목', '금', '토', '일'];
const DEFAULT_DISPLAY_LECTURE_COLOR = { private: false, group: false };

export const state = {
  calendarViewType: utils.localStorage.get('calendarViewType') || 'week',
  calendarDisplayHours: utils.localStorage.get('calendarDisplayHours') || [...DEFAULT_DISPLAY_HOURS],
  calendarDisplayWeekdays: utils.localStorage.get('calendarDisplayWeekdays') || [...DEFAULT_DISPLAY_WEEKDAYS],
  calendarDisplayLectureColor: utils.localStorage.get('calendarDisplayLectureColor') || {
    ...DEFAULT_DISPLAY_LECTURE_COLOR,
  },
  present: {
    ...DEFAULT_DATES,
  },
  datepickerValues: {
    ...DEFAULT_DATES,
  },
  filters: {
    eventType: 'all',
    staff: {
      showAll: true,
      ids: [],
    },
  },
  loading: false,
  events: [],
  staffOptions: [],
  staffListOrders: utils.localStorage.get('staffListOrders') || [],
  rooms: [...DEFAULT_ROOMS],
};

export const getters = {
  calendarViewType: state => state.calendarViewType,
  calendarDisplayHours: state => state.calendarDisplayHours,
  calendarDisplayWeekdays: state => state.calendarDisplayWeekdays,
  calendarDisplayLectureColor: state => state.calendarDisplayLectureColor,
  present: state => state.present,
  datepickerValues: state => state.datepickerValues,
  filters: state => state.filters,
  loading: state => state.loading,
  events: state => state.events,
  staffOptions: state => state.staffOptions,
  rooms: state => state.rooms,
};

export const mutations = {
  SET_CALENDAR_VIEW_TYPE(state, type) {
    utils.localStorage.set('calendarViewType', type);
    state.calendarViewType = type;
  },

  SET_CALENDAR_DISPLAY_HOURS(state, hours) {
    utils.localStorage.set('calendarDisplayHours', hours);
    state.calendarDisplayHours = [...hours];
  },

  SET_CALENDAR_DISPLAY_WEEKDAYS(state, weekdays) {
    utils.localStorage.set('calendarDisplayWeekdays', weekdays);
    state.calendarDisplayWeekdays = [...weekdays];
  },

  SET_CALENDAR_DISPLAY_LECTURE_COLOR(state, isShowlectureColor) {
    utils.localStorage.set('calendarDisplayLectureColor', isShowlectureColor);
    state.calendarDisplayLectureColor = isShowlectureColor;
  },

  SET_DATEPICKER_VALUES(state, date) {
    if (state.calendarViewType === 'week') {
      state.datepickerValues.week = getMonday(date);
    } else if (state.calendarViewType === 'month') {
      state.datepickerValues.month = moment(date)
        .startOf('month')
        .format('YYYY-MM-DD');
    } else {
      state.datepickerValues.date = moment(date).format('YYYY-MM-DD');
    }
  },

  SET_FILTERS(state, filters) {
    state.filters = {
      ...state.filters,
      ...filters,
    };
  },

  SET_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_EVENTS(state, { lectures, counsels, etcSchedules, currentUser }) {
    state.events = utils.combineEvents(lectures, counsels, etcSchedules, currentUser);
  },

  SET_STAFF_OPTIONS(state, staffs) {
    if (!staffs) return (state.staffOptions = []);

    const staffIds = staffs.map(staff => staff.id);

    if (state.filters.staff.showAll) {
      state.filters.staff.ids = staffIds;
    }

    if (!state.staffListOrders.length) {
      state.staffListOrders = staffIds;
      utils.localStorage.set('staffListOrders', staffIds);
    }

    state.staffOptions = _.sortBy(
      staffs.map(staff => {
        const order = state.staffListOrders.findIndex(id => id == staff.id);
        staff.order = order >= 0 ? order : null;
        return staff;
      }),
      ['order', 'id'],
    );
  },

  SET_STAFF_LIST_ORDERS(state, orders) {
    utils.localStorage.set('staffListOrders', orders);
    state.staffListOrders = [...orders];
  },

  SET_ROOMS(state, rooms) {
    state.rooms = [...DEFAULT_ROOMS, ...rooms];
  },
};

export const actions = {
  async getStaffOptions({ commit }) {
    try {
      const res = await api.staff.getAll();
      commit('SET_STAFF_OPTIONS', res.data.staffs);
    } catch (error) {
      commit('SET_STAFF_OPTIONS');
    }
  },

  async getEvents({ state, commit }) {
    try {
      commit('SET_LOADING', true);
      const calendarViewType = state.calendarViewType.split('|')[0];
      const start_date = state.datepickerValues[calendarViewType];
      let end_date = state.datepickerValues[calendarViewType];
      if (calendarViewType === 'week') {
        end_date = moment(start_date)
          .add(6, 'd')
          .format('YYYY-MM-DD');
      } else if (calendarViewType === 'month') {
        end_date = moment(start_date)
          .endOf('month')
          .format('YYYY-MM-DD');
      }

      const params = {
        start_date,
        end_date,
      };

      /* 타임아웃을 피하기 위해서 page로 쪼개서 api 요청을 여러번 하고 데이터를 합쳐주는 작업 */
      const limit = 45;
      const lectureCountRes = await api.lecture.getCount(params);
      const lectureLastPage = parseInt(lectureCountRes.data / limit) + 1;
      const allLecturePages = Array.from(new Array(lectureLastPage), (_, i) => i + 1);

      const etcCountRes = await api.etcSchedule.getCount(params);
      const etcLastPage = parseInt(etcCountRes.data / limit) + 1;
      const allEtcPages = Array.from(new Array(etcLastPage), (_, i) => i + 1);

      const allRes = await Promise.all([
        ...allLecturePages.map(page =>
          api.lecture.getAll({ ...params, limit, page, with: 'bookings.userTicket.ticket;bookings.member;staff' }),
        ),
        ...allEtcPages.map(page => api.etcSchedule.getAll({ ...params, limit, page })),
        api.counsel.getAll(params),
      ]);

      const lecturesOrigin = allRes
        .filter(item => item.config.url.includes('lectures'))
        .reduce((prev, cur) => prev.concat(cur.data.data), []);
      const lectures = lecturesOrigin.map(lecture => {
        const bookings = _.sortBy(lecture.bookings, 'id');
        return { ...lecture, bookings };
      });
      const etcSchedules = allRes
        .filter(item => item.config.url.includes('etcSchedule'))
        .reduce((prev, cur) => prev.concat(cur.data.data), []);
      const counsels = allRes[allRes.length - 1].data;

      const currentUser = utils.localStorage.get('currentUser');
      commit('SET_EVENTS', {
        lectures: lectures,
        counsels: counsels,
        etcSchedules,
        currentUser,
      });
    } catch (error) {
      commit('SET_EVENTS', {
        lectures: [],
        counsels: [],
      });
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getRooms({ commit }) {
    try {
      const res = await api.studio.rooms.get();
      commit('SET_ROOMS', res.data);
    } catch (error) {
      commit('SET_ROOMS', []);
    }
  },
};
