/**
 * 이미지 URL 생성
 *
 * @param {Object} image ex) user.avatars[0]
 * @param {String} size ex) 32x32
 */

export default (image, size) => {
  const defaultImage = require('@/assets/images/dummy_profile_90x90.png');
  try {
    if (!image) return defaultImage;
    if (typeof image === 'string') return image;
    const { host, name, path } = image.image;
    const [w, h] = size.split('x');
    return `${host}${path}/${name}?w=${w}&h=${h}&f=webp&q=80`;
  } catch (error) {
    return defaultImage;
  }
};
