import axios from '../axios';

export default {
  // 강사 목록
  getAll: params =>
    axios.get('/staff/staff', {
      params,
    }),

  // 수강권 발급 전용 강사 목록
  getAllOnlyStaff: () => axios.get('/staff/staff/only'),

  // 이전에 삭제된 강사도 포함된 강사 목록
  getAllWithDeleted: () => axios.get('/staff/staff/deletedStaffs'),

  // 개별 강사
  get: id => {
    const params = {
      with: [
        'avatars',
        'contactInfos',
        'careers',
        'account',
        'roles',
        'address',
        'profile',
        'workingHour.events.recurringPattern',
        'restHours.events.recurringPattern',
      ],
    };
    return axios.get(`/staff/staff/${id}`, {
      params,
    });
  },

  // 강사 등록
  create: data => {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return axios.post('/staff/staff', data, {
      headers,
    });
  },

  // 강사 수정
  update: (id, data) => {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return axios.post(`/staff/staff/${id}`, data, {
      headers,
    });
  },

  // 강사 삭제
  delete: id => axios.delete(`/staff/staff/${id}`),

  // 강사 아바타 삭제
  deleteAvatar: id => axios.delete(`/staff/staff/${id}/avatar`),

  //강사 근무 시간
  workTimeSchedule: id => axios.get(`/v2/staff/staff-schedule/${id}`),

  //강사 근무 시간 - 추가, 수정, 삭제
  workTimeUpdate: (id, params) => {
    return axios.post(`/v2/staff/staff-schedule/${id}`, params);
  },
};
