/**
 * 이미지 URL 생성
 *
 * @param {Object} image ex) image
 * @param {String} size ex) 32
 * @param {String} defaultImg ex) dummy_profile_90x90.png
 */

export default (image, size, defaultImg) => {
  try {
    if (typeof image === 'string') return image;
    const { host, name, path } = image;
    return `${host}${path}/${name}?w=${size}&h=${size}&f=webp&q=80`;
  } catch (error) {
    if (defaultImg) return require(`@assets/images/${defaultImg}`);
    return require('@assets/images/dummy_profile_90x90.png');
  }
};
